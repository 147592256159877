<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1 style="text-align: center">{{ translationAll.tcWebSiteSecurityManager }}</h1>
      <div>
        <section class="section-4 bg-block mb-4">
          <header class="mb-3">
            <div class="g-row mb-0 mb-sm-3">
              <div class="font-style-1 col-lg-6">{{ translationAll.tcName }}</div>
              <div class="font-style-1 col-lg-3 d-none d-sm-block">{{ translationAll.tcSiteAdministrator }}</div>
              <div class="font-style-1 col-lg-3 d-none d-sm-block">{{ translationAll.tcContentContributor }}</div>
            </div>
          </header>
          <div class="body">
            <div v-for="(wsm, index) in wsmData" :key="index" :obj="wsm">
              <div class="row mb-0 mb-sm-3">
                <div class="col-lg-6 mb-3 mb-sm-0 d-flex align-items-center">
                  <span class="flex-0">
                    <p>
                      <a href="#" class="secure" style="underline"
                        @click.prevent="individualStoreKeyAndRedirect(wsm.ind_key)">{{ wsm.name }}</a>
                    </p>
                  </span>
                </div>
                <div class="col-lg-3 mb-3 mb-sm-0 d-flex align-items-center" style="text-align: right">
                  <b-form-checkbox :id="'siteAdminstrator' + index" v-model="wsm.site_administrator_flag"
                    @change="setSiteAdminCheck($event, wsm.ind_key)" class="" type="text">
                    <span class="mobfont d-lg-none d-xl-block">{{ translationAll.tcSiteAdministrator }}</span>
                  </b-form-checkbox>
                </div>
                <div class="col-lg-3 mb-3 mb-sm-0 d-flex align-items-center" style="text-align: right">
                  <b-form-checkbox :id="'contentContributor' + index" v-model="wsm.content_contributor_flag"
                    @change="setContentDistributorCheck($event, wsm.ind_key)" class="" type="text">
                    <span class="mobfont d-lg-none d-xl-block">{{ translationAll.tcContentContributor }}</span>
                  </b-form-checkbox>
                </div>
              </div>
              <hr class="" />
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-4">
          <div class="body">
            <div class="d-flex">
              <b-button variant="primary" @click="handleEditClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
                {{ translationAll.tcSaveChanges }}
              </b-button>
              <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
                {{ translationAll.tcCancel }}
              </b-button>
            </div>
          </div>
        </section>
      </div>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iMapPoint from '@/assets/svgs/iMapPoint.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'website-security-manager',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      requestData: {
        user_ind_key: '',
        data: [],
      },
      availableList: [],
      meetingNote: '',
      siteAdminstratorFlagText: 'siteAdminstrator',
      contentContributorFlagText: 'contentContributor',
      selectedManager: '',
    }
  },
  methods: {
    ...mapActions({
      getWSMData: 'campModule/getWSMData',
      retrieveMeetingTypes: 'card/retrieveMeetingTypes',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      upsertVideoVisit: 'card/upsertVideoVisit',
      upsertWSM: 'campModule/upsertWSM',
    }),
    async handleEditClick() {
      if (this.requestData.data.length <= 0) {
        this.$swal({
          icon: 'error',
          text: this.translationAll.tcErrorNoRoleToEdit,
          confirmButtonText: this.translationAll.tcOk || 'Ok',
        })
        return
      }
      this.setLoadingStatus(true)

      let response = await this.submitRequest(this.requestData)
      if (response == true) {
        this.requestData.data = []
        await this.pageLoad()
        this.$swal({
          icon: 'success',
          text: this.translationAll.tcDataUpdated,
          confirmButtonText: this.translationAll.tcOk || 'Ok',
        })
      } else {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          text: this.translationAll.tcErrorEditingRoles,
          confirmButtonText: this.translationAll.tcOk || 'Ok',
        })
      }
      this.setLoadingStatus(false)
    },
    async submitRequest(data) {
      try {
        let response = await this.upsertWSM(data)
        return response
      } catch (e) {
        return false
      }
    },
    handleCancelClick() {
      this.$router.push({ name: 'camp-officers-home' })
    },
    setSiteAdminCheck(event, indKey) {
      let index = this.requestData.data.findIndex((data) => data.ind_key == indKey && data.role == 'SiteAdminstrator')
      if (index < 0) {
        this.requestData.data.push({
          ind_key: indKey,
          delete: !event,
          role: 'SiteAdminstrator',
        })
      } else {
        this.requestData.data.splice(index, 1)
      }
    },
    setContentDistributorCheck(event, indKey) {
      let index = this.requestData.data.findIndex((data) => data.ind_key == indKey && data.role == 'ContentDistributor')
      if (index < 0) {
        this.requestData.data.push({
          ind_key: indKey,
          delete: !event,
          role: 'ContentContributor',
        })
      } else {
        this.requestData.data.splice(index, 1)
      }
    },
    async pageLoad() {
      this.setLoadingStatus(true)
      await Promise.all([await this.getWSMData(this.userOrgKey), this.getViewTranslations()]).then(() => {
        this.setLoadingStatus(false)
        this.requestData.user_ind_key = this.userId
      })
    },
    async individualStoreKeyAndRedirect(data) {
      await this.setSelectedIndividualKey(data)
      this.$router.push({ path: '/programs/mb/member-profile/' })
    },
  },
  async created() {
    await this.getViewTranslations()
    await this.pageLoad()
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      meetingTypes: 'card/getMeetingTypes',
      prefCulture: 'user/userPreferredCulture',
      restockingVisitDetails: 'card/getRestockingVisitDetails',
      selectedVisitKey: 'churchMinistry/selected_visit_key',
      userId: 'user/userId',
      userOrgKey: 'user/userCampKey',
      wsmData: 'campModule/wsmData',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    facility() {
      return (
        this.restockingVisitDetails.facility || {
          contact_name: '',
          org_name: '',
          address_line_1: '',
        }
      )
    },
    translationAll() {
      return !!this.translations.tcWebSiteSecurityManager
        ? this.translations
        : {
          tcCancel: 'Cancel',
          tcContentContributor: 'Content Contributor',
          tcDataUpdated: 'Updated your data successfully',
          tcErrorEditingRoles: 'There was an error in editing security roles, please try again or contact admin',
          tcErrorNoRoleToEdit: 'You have not selected a new role to edit.',
          tcName: 'Name',
          tcOk: 'Ok',
          tcSaveChanges: 'Save',
          tcSiteAdministrator: 'Site Administrator',
          tcWebSiteSecurityManager: 'Website Security Manager',
        }
    },
  },
  components: {
    iCalendar: iCalendar,
    iMapPoint: iMapPoint,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    securityBanner: SecurityBanner,
  },
}
</script>


<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.padrspn {
  @include breakpoint(sm) {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

.mobfont {
  @include breakpoint(sm) {
    font-size: 14px;
  }
}

.font-style-1.col-lg-6,
.font-style-1.col-lg-3 {
  font-size: 16px;

  @include breakpoint(sm) {
    font-size: 14px;
    padding: 0;
  }
}

h3 {
  line-height: 1;
}

hr {
  margin: 25px 0;
  background-color: #979797;

  @include breakpoint(sm) {
    margin: 0 0 1rem 0;
  }
}

a.secure {
  color: #003946;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}
</style>
